<template>
  <div class="Resource">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="计算机科学">计算机科学</el-menu-item>
      <el-menu-item index="材料科学">材料科学</el-menu-item>
      <el-menu-item index="电子电工">电子电工</el-menu-item>
      <el-menu-item index="教育学">教育学</el-menu-item>
      <el-menu-item index="图书情报">图书情报</el-menu-item>
      <el-menu-item index="合成生物学">合成生物学</el-menu-item>
      <el-menu-item index="人工智能">人工智能</el-menu-item>
      <el-menu-item index="机械工程">机械工程</el-menu-item>
    </el-menu>
    <div>
      <!-- 头部 -->
      <div class="theme_top dix" v-if="this.$route.query.type!=0">
        <img class="top_img" :src="api+theme.img" />
        <span class>
          <div class="theme_title dix">
            {{theme.title}}
            <div @click="tapDyZt(theme)">订阅</div>
          </div>
          <div class="theme_content">{{theme.content}}</div>
        </span>
      </div>
      <div v-else class="keywords theme_titles">
        <span class="keysword">{{keywords}}</span>
        <div @click="tapDyZtkeyword()">订阅</div>
      </div>
      <div class="contents">
        <!-- 列表 -->
        <div class="list" v-for="item in list" :key="item.id">
          <div class="cur" @click="change1(item)">
            <el-image class="List_format" :src="item.format"></el-image>
            <span class="List_name">{{item.name}}</span>
          </div>
          <div class="bq">
            <span
              class="cur"
              @click="Jump(items)"
              v-for="(items,indexs) in item.keyword"
              :key="indexs"
            >{{items}}</span>
          </div>
          <div class="biaoqian">
            <span>{{item.Time}}</span>
            <span>共{{item.pageall}}页</span>
            <span>{{item.download}}次下载</span>
            <span>{{item.Price}}知识币</span>
            <span>作者：{{item.UserauthorName}}</span>
          </div>
          <div class="xiazai">
            <el-button type="primary" size="small" @click.stop="change(item)">下载</el-button>
          </div>
        </div>
        <div class="nolist" v-if="list.length==0">暂无相关资源</div>
        <el-pagination
          background
          :hide-on-single-page="true"
          :page-size="pageSize"
          @current-change="currentchange"
          layout="prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { showTimeYearDate } from "@/js/common";
import { INSERT, QUERY, DELETE, QUERYED, UPDATES } from "@/services/dao.js";
export default {
  data() {
    return {
      pageNum: 0, //页数
      pageSize: 15, //每页条数
      total: 0, //总条数
      keywords: this.$route.query.name,
      api: this.api.LoginURL.concat(), //图片路径
      activeIndex: "1",
      list: [], //资源列表
      theme: {}, //主题
      states: 0, //状态
      queryname: ""
    };
  },
  created() {
    this.queryname = this.$route.query.name;
    this.Refresh(this.queryname, this.states);
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (
        to.query.name != from.query.name ||
        to.query.type != from.query.type
      ) {
        //刷新
        this.keywords=this.$route.query.name;
        this.queryname = this.$route.query.name;
        this.Refresh(this.queryname, this.states);
      }
    }
  },
  methods: {
    Jump(name) {
      this.$router.push({
        path: "/Resource_theme",
        query: {
          name: name,
          type: 0
        }
      });
    },
    //切换页数
    currentchange(val) {
      this.pageNum = val - 1;
      this.init(this.pageSize, this.pageNum, this.queryname, this.states);
    },
    Refresh(name, sum) {
      this.themes(name);
      this.init(this.pageSize, this.pageNum, name, sum);
    },
    // 列表
    async init(limit, offest, name, sum) {
      this.list = [];
      let info = null;
      if (this.$route.query.type != 0) {
        info = await QUERY(
          "post",
          "",
          "ZYDocument(limit: " +
            limit +
            ", offset: " +
            offest +
            ', where: {classification: {_like: "%' +
            name +
            '%"}}){  level  Price pageall keyword  Time   collection  download  read  id     name  score  user format classification UserauthorName} ZYDocument_aggregate(where: {classification: {_like: "%' +
            name +
            '%"}}){    aggregate {      count    }  }'
        );
      }
      if (this.$route.query.type == 0 && sum == 0) {
        info = await QUERY(
          "post",
          "",
          "ZYDocument(limit: " +
            limit +
            ", offset: " +
            offest +
            ', where: {keyword: {_like: "%' +
            name +
            '%"}}){  level  Price pageall keyword  Time   collection  download  read  id     name  score  user format classification UserauthorName} ZYDocument_aggregate(where: {keyword: {_like: "%' +
            name +
            '%"}}){    aggregate {      count    }  }'
        );
      }
      if (this.$route.query.type == 0 && sum == 1) {
        info = await QUERY(
          "post",
          "",
          "ZYDocument(limit: " +
            limit +
            ", offset: " +
            offest +
            ', where: {classification: {_like: "%' +
            name +
            '%"},keyword: {_like: "%' +
            this.keywords +
            '%"}}){  level  Price pageall keyword  Time   collection  download  read  id     name  score  user format classification UserauthorName} ZYDocument_aggregate(where: {classification: {_like: "%' +
            name +
            '%"},keyword: {_like: "%' +
            this.keywords +
            '%"}}){    aggregate {      count    }  }'
        );
      }
      for (let i = 0; i < info.data.ZYDocument.length; i++) {
        info.data.ZYDocument[i].keyword = eval(info.data.ZYDocument[i].keyword);
        info.data.ZYDocument[i].Time = showTimeYearDate(
          info.data.ZYDocument[i].Time
        );
        switch (info.data.ZYDocument[i].format) {
          case "docx":
            info.data.ZYDocument[
              i
            ].format = require("@/assets/icon/WORD@2x.png");
            break;
          case "txt":
            info.data.ZYDocument[
              i
            ].format = require("@/assets/icon/txt(1)@2x.png");
            break;
          case "pdf":
            info.data.ZYDocument[
              i
            ].format = require("@/assets/icon/PDF48@2x.png");
            break;
          case "excel":
            info.data.ZYDocument[
              i
            ].format = require("@/assets/icon/excel(2) 拷贝@2x.png");
            break;
          case "ppt":
            info.data.ZYDocument[
              i
            ].format = require("@/assets/icon/ppt@2x.png");
            break;
          default:
            info.data.ZYDocument[i].format = require("@/assets/icon/？@2x.png");
        }
      }
      this.list = info.data.ZYDocument;
      this.total = info.data.ZYDocument_aggregate.aggregate.count;
    },
    //   主题
    async themes(name) {
      if (this.$route.query.type != 0) {
        this.theme = [];
        let res = await QUERYED(
          "post",
          "",
          'TtTheme(where: {title:{_eq:"' + name + '"}}){id content title img }'
        );
        this.theme = res.data.TtTheme[0];
      }
    },
    handleSelect(key) {
      this.states = 1;
      this.queryname = key;
      this.Refresh(this.queryname, this.states);
    },
    // 下载
    change(value) {
      if (
        this.$store.state.userinfo.id < 0 ||
        this.$store.state.userinfo.id == "" ||
        this.$store.state.userinfo.id == null ||
        this.$store.state.userinfo.id == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
      } else {
        this.$router.push({
          path: "/Resource_download",
          query: {
            id: value.id
          }
        });
      }
    },
    //详情
    change1(value) {
      if (
        this.$store.state.userinfo.id < 0 ||
        this.$store.state.userinfo.id == "" ||
        this.$store.state.userinfo.id == null ||
        this.$store.state.userinfo.id == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
      } else {
        this.$router.push({
          path: "/Resource_details",
          query: {
            id: value.id
          }
        });
      }
    }, //订阅主题
    async tapDyZt(item) {
      let userId = this.$store.state.userinfo.id;
      if (!userId) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      let zyId = item.id;
      let res = await QUERYED(
        "post",
        "",
        `UserAttentionZy(where:{zyId:{_eq:${zyId}},userId:{_eq:${userId}},type:{_eq:"1"}}) {id}`
      );
      if (!res.success) {
        this.$message({
          message: "订阅失败，请重试!",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      if (res.data.UserAttentionZy.length > 0) {
        this.$message({
          message: "当前主题已订阅！",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      let UserAttentionZy = await INSERT(
        "post",
        "",
        `insert_UserAttentionZy(objects:{zyId:${zyId},userId:${userId},type:"1"}){affected_rows}`
      );
      if (UserAttentionZy.success) {
        this.$message({
          message: "主题订阅成功！",
          type: "success",
          duration: "1000"
        });
      } else {
        this.$message({
          message: "订阅失败，请重试！",
          type: "warning",
          duration: "1000"
        });
      }
    },
    //订阅标签
    async tapDyZtkeyword() {
      let userId = this.$store.state.userinfo.id;
      if (!userId) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      let zyId = this.keywords; //标签
      let res = await QUERYED(
        "post",
        "",
        `UserAttentionZy(where:{keyword :{_eq:"${zyId}"},userId:{_eq:${userId}},type:{_eq:"3"}}) {id}`
      );
      if (!res.success) {
        this.$message({
          message: "订阅失败，请重试！",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      if (res.data.UserAttentionZy.length > 0) {
        this.$message({
          message: "当前标签已订阅！",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      let UserAttentionZy = await INSERT(
        "post",
        "",
        `insert_UserAttentionZy(objects:{keyword:"${zyId}",userId:${userId},type:"3"}){affected_rows}`
      );
      if (UserAttentionZy.success) {
        this.$message({
          message: "标签订阅成功！",
          type: "success",
          duration: "1000"
        });
      } else {
        this.$message({
          message: "订阅失败，请重试！",
          type: "warning",
          duration: "1000"
        });
      }
    }
  }
};
</script>
<style scoped>
.cur {
  cursor: pointer;
}
.dix {
  display: flex;
}
.Resource {
  width: 1100px;
  margin: auto;
}
.el-menu--horizontal > .el-menu-item {
  width: 12.5%;
  background-color: #0e86f6;
  color: #ffffff;
}
.theme_top {
  /* width: 100%; */
  background-color: #1a417c;
  color: rgb(255, 255, 255);
  padding: 20px 10px 20px 10px;
  /* justify-content: center; */
  align-items: center;
}
.top_img {
  width: 300px;
  height: 180px;
  margin-right: 10px;
}
.theme_title {
  font-size: 23px;
  line-height: 50px;
  height: 50px;
  width: 100%;
  position: relative;
  color: rgb(140, 196, 251);
}
.theme_titles {
  /* font-size: 23px;
  line-height: 50px;
  height: 50px; */
  width: 100%;
  position: relative;
  color: rgb(140, 196, 251);
}
.theme_titles > div {
  width: 80px;
  height: 35px;
  background-color: rgb(14, 134, 246);
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 6px;
  cursor: pointer;
}
.theme_title > div {
  width: 80px;
  height: 35px;
  background-color: rgb(14, 134, 246);
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 6px;
  cursor: pointer;
}
.theme_content {
  font-size: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  line-height: 25px;
}
/* 详情内容 */
.contents {
  padding: 20px;
  background-color: rgb(255, 255, 255);
}
.list {
  padding: 10px 10px 20px 10px;
  border-bottom: 1px dashed slategrey;
}
.list:last-child {
  border: none;
}
.List_format {
  width: 30px;
  height: 35px;
  margin-right: 10px;
}
.list > div {
}
.List_name {
  font-size: 17px;
  line-height: 45px;
  height: 35px;
  font-weight: bold;
  vertical-align: bottom;
  display: inline-block;
  width: 90%;
}
.bq {
  height: 30px;
  line-height: 40px;
  /* background-color: violet; */
}
.bq span {
  font-size: 13px;
  border-radius: 30px;
  border: 0.007353rem solid #bfbfbf;
  /* padding: 0.036765rem; */

  background-color: #eeeeee;
  padding: 2px 13px 2px 13px;
  margin-right: 10px;
}
.biaoqian {
  font-size: 13px;
  /* line-height: 35px; */
  /* background: rgb(14, 134, 246); */
  margin-top: 10px;
  display: inline-block;
}
.biaoqian > span {
  /* background-color: violet; */
  padding: 0 15px 0 15px;
  border-right: 1px solid rgb(129, 128, 128);
}
/* 去除最后一个div的样式 */
.biaoqian > span:last-child {
  border-right: none;
}
/* not(:first-child) 除开第一个 */
.biaoqian > span:first-child {
  padding-left: 0;
}
.xiazai {
  /* display: inline-block; */
  float: right;
}
::v-deep .xiazai .el-button {
  font-size: 14px;
  padding: 10px 20px 10px 20px;
}
.nolist {
  font-size: 16px;
  color: rgb(153, 153, 152);
  text-align: center;
}
.keywords {
  margin-top: 20px;
}
.keysword {
  display: inline-block;
  margin-bottom: 20px;
  color: rgb(9, 131, 245);
  font-weight: 500;
  background-color: rgb(205, 231, 253);
  height: 30px;
  line-height: 30px;
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  /* 超过固定宽度省略号显示 */
  max-width: 86%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 7px;
  margin-left: 7px;
  vertical-align: top;
  cursor: pointer;
}
</style>